import React from 'react';
import { connect } from 'react-redux';
import { submitCart } from '../../redux/Cart';
import BookStack from '../../components/BookStack';
import { Formik, Form, FieldArray } from 'formik';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import FormField from '../../components/FormField';
import Loader from '../../components/Loader';
import { XCircle, CheckCircle } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { PATH_ACCOUNT } from '../../constants';
import { getValues } from '../../functions/getValues';
import { formatDollar } from '../../functions/helpers';
import Incognito from '../../components/Incognito';
import sanitizeHtml from 'sanitize-html';

const countProductsReducer = (accumulator, currentValue) => accumulator + currentValue.productCount;

const mapStateToProps = (state, ownProps) => {
    return {
        userData: state.user.data,
        isLoadingCart: state.cart.isLoadingCart,
        cartItems: state.cart.currentCart.items || [],
        cartTotal: state.cart.currentCart && state.cart.currentCart.totalAmount ? state.cart.currentCart.totalAmount : 0,
        cartQty: state.cart.currentCart && state.cart.currentCart.items ? state.cart.currentCart.items.reduce(countProductsReducer, 0) : 0,
        cartCovers: state.cart.currentCart && state.cart.currentCart.items ? getValues(state.cart.currentCart.items, 'imageUrl', 4) : [],
        accountId: state.user.data ? state.user.data.accountId : null,
        accountEmail: state.user.data ? state.user.data.email : null,
        actingAsUser: state.user.data ? state.user.data.actingAsUser : null
    };
}
const mapDispatchToProps = dispatch => {
    return {
        submitCart: (p,c,e) => { dispatch(submitCart(p,c,e)); }
    };
}

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

class Checkout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cartTotal: null,
            cartQty: null,
            cartCovers: null,
            submitSuccess: false,
            orderId: null,
            serverError: false
        };
    }
    
    componentDidMount() {
        setTimeout(() => {window.scrollTo(0, 0);}, 100);
    }

    validateForm = (values) => {
        let errors = {};
        if (!values.extraAccountId) {
            errors.extraAccountId = "Please enter an Account Number";
        }
        if (!values.orderReference || values.orderReference === "") {
            errors.orderReference = "Please enter an order reference";
        }
        if (values.addEmails && values.addEmails.length > 0) {
          for (let i=0; i<values.addEmails.length; i++) {
            let v = values.addEmails[i];
            if (!emailRegex.test(v)) {
              errors[`addEmails.${i}`] = "Please enter a valid email address";
            }
          }
        }
        return errors;
    }

    submitForm = (values, formikBag) => {
        let payload = {
            extraAccountId: values.extraAccountId,
            orderReference: sanitizeHtml(values.orderReference, {allowedTags: [], allowedAttributes: {}}),
            notes: sanitizeHtml(values.notes, {allowedTags: [], allowedAttributes: {}}),
            additionalEmails: values.addEmails.filter(Boolean).join()
        };
        // console.log(payload);
        this.props.submitCart(
            payload,
            (response) => {
                let qty = response.items.reduce((a, v) => a + v.productAmount, 0);
                this.setState({
                    submitSuccess: true,
                    orderId: response.id,
                    cartTotal: response.amount,
                    cartQty: qty,
                    cartCovers: getValues(response.items, 'imageUrl', 4)
                });
                formikBag.setSubmitting(false);
            },
            (error) => {
                // console.error(error);
                let msg = "An error has occured";
                if (error.body && error.body.message) {
                    msg = error.body.message;
                }
                this.setState({serverError: msg});
                formikBag.setSubmitting(false);
            }
        );
    }

    render() {
        const {isLoadingCart, accountId, accountEmail, actingAsUser, cartItems} = this.props;
        const {submitSuccess, orderId, serverError} = this.state;
        const cartTotal = submitSuccess ? this.state.cartTotal : this.props.cartTotal;
        const cartQty = submitSuccess ? this.state.cartQty : this.props.cartQty;
        const cartCovers = submitSuccess ? this.state.cartCovers : this.props.cartCovers;
        let hardieGrantTotal = 0;
        let nonHardieGrantTotal = 0;
        if (this.props.userData.country === "NZ" && cartItems.length > 0) {
          for (let i=0; i<cartItems.length; i++) {
            let item = cartItems[i];
            if (item.product?.publishingCompany === "HARDIE GRANT") {
              hardieGrantTotal += item.productCount;
            } else {
              nonHardieGrantTotal += item.productCount;
            }
          }
        }
        return (
            <div className="page checkout">

                {isLoadingCart && <Loader type="placeholder" />}

                {!isLoadingCart && cartQty === 0 &&
                    <h2 className="text-center py-5">Your cart is empty.</h2>
                }

                {!isLoadingCart && cartQty > 0 &&
                    <div>
                        <div className="cart-header mb-5">
                            <div className="row justify-content-center">
                                <div className="col-10">
                                    <div className="row">

                                        <div className="col-12 col-sm-5 col-md-4">
                                            <div className="pt-5 pt-md-0 book-cover">
                                                <div className="book-cover-wrapper">
                                                    <BookStack size="300" images={cartCovers} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="cart-details py-5 pl-sm-3 pl-md-5">
                                                <h1 className="mb-3">Basket total</h1>
                                                {hardieGrantTotal > 0 && <>
                                                  <p className="mb-1">{hardieGrantTotal} item{hardieGrantTotal > 1 && "s"} to be fulfilled by TDLC on behalf of Hardie Grant</p>
                                                  <p className="mb-3">{nonHardieGrantTotal} item{nonHardieGrantTotal > 1 && "s"} to be fulfilled by Harper Entertainment Distribution Services (HEDS)</p>
                                                </>}
                                                <div className="items-count mb-3">{cartQty} items total</div>
                                                <hr className="my-3" />
                                                <div className="price mb-3">{formatDollar(cartTotal)}</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center py-5">
                            <div className="col-md-10">
                                {submitSuccess && <div>
                                    <h2 className="mb-3">Order complete <CheckCircle size={32} className="text-success ml-2" /></h2>
                                    <p>Thank you, your order has been submitted.</p>
                                    <p>You will receive a confirmation email with order details shortly.</p>
                                    <p>Order number: <b>{orderId}</b></p>
                                </div>}

                                {!submitSuccess && <Formik
                                    validate={this.validateForm}
                                    onSubmit={this.submitForm}
                                    initialValues={{
                                        extraAccountId: accountId,
                                        addEmails: []
                                    }}
                                >
                                    {(formikBag) => (
                                        <Form className="position-relative">
                                            {formikBag.isSubmitting && <Loader />}
                                            <div className="row">
                                                <div className="col-12 col-lg-6">
                                                    {(actingAsUser && actingAsUser !== null) &&
                                                        <Alert variant="warning"><Incognito size={18} /> Submitting on behalf of <b>{actingAsUser.firstName} {actingAsUser.lastName} ({actingAsUser.accountId})</b></Alert>
                                                    }
                                                    <div className="mb-4">
                                                      <FormField id="orderReference" label="Reference*" formikBag={formikBag} />
                                                    </div>
                                                    <p className="mb-4">
                                                        Email address<br /> {accountEmail}
                                                        <small className="form-text">If this is not your email, you can update it in your <Link to={PATH_ACCOUNT}>account</Link></small>
                                                    </p>

                                                    <FieldArray name="addEmails">
                                                        {({ remove, push }) => (
                                                            <div className="mb-3">
                                                                <p className="mb-1">Additional emails</p>
                                                                {formikBag.values.addEmails && formikBag.values.addEmails.map((mail, i) => (
                                                                    <div className="row align-items-center" key={`mail${i}`}>
                                                                        <div className="col">
                                                                            <FormField id={`addEmails.${i}`} label="Email" formatValue={(v) => { return v.replace(/\s/g, ''); }} formikBag={formikBag} />
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <Button type="button" variant="link" onClick={() => remove(i)}><XCircle size={24} /></Button>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                                <Button type="button" variant="outline-primary" size="sm" onClick={() => push("")}>+ add another email</Button>
                                                            </div>
                                                        )}
                                                    </FieldArray>
                                                </div>
                                                <div className="col-12 col-lg-6">
                                                    <FormField id="notes" label="Notes" formikBag={formikBag} type="textarea" rows={5}></FormField>
                                                </div>
                                            </div>
                                            
                                            {(actingAsUser && actingAsUser !== null) &&
                                                <Alert variant="warning"><Incognito size={18} /> Submitting on behalf of <b>{actingAsUser.firstName} {actingAsUser.lastName} ({actingAsUser.accountId})</b></Alert>
                                            }
                                            
                                            {serverError && <p className="server-error text-danger py-3">{serverError}</p>}
                                            <div className="text-right mt-4">
                                                <Button type="submit" variant="primary">Submit order</Button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>}
                            </div>
                        </div>

                    </div>
                }

            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Checkout)
